import React, {Component} from "react";
import {Tab} from "semantic-ui-react";
import SecureTabPane from "../../SecureTabPane";
import TimeMachineMetadataDetailView from "./TimeMachineMetadataDetailView";
import TimeMachinePurgeView from "./TimeMachinePurgeView";
import BasicModal from "../../BasicModal/BasicModal";

export default class TimeMachineMasterDetailView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showModal: false,
            activeIndex: 0,
            saved: false,
            changeEditor: false
        }
    }

    handleLeave = () => {
        this.setState({
            activeIndex: 1,
            changeEditor: false,
            saved: false,
            showModal: false
        })
    }

    handleStay = () => {
        this.setState({
            showModal: false
        })
    }

    handleTabChange = (e, { activeIndex: newIndex }) => {
        if (this.state.changeEditor && !this.state.saved ){
            this.setState({showModal: true})
            return
        }
        this.setState({activeIndex: newIndex});
    };

    handleSetSave = (saved) => {
        this.setState({
            saved
        });
    }

    handleSetChangeEditor = (changeEditor) => {
        this.setState({
            changeEditor
        });
    }

    render() {
        return (
            <>
                <BasicModal
                    onClose={this.handleStay}
                    onOpen={this.handleLeave}
                    open={this.state.showModal}
                    headerText={""}
                    modalContent={"Are you sure you want to leave without saving?"}
                />
                <Tab 
                    onTabChange={this.handleTabChange}
                    activeIndex={this.state.activeIndex}
                    panes={[
                        {
                            menuItem: {
                                key: "time-machine-md-tab",
                                content: <label>Time Machine Metadata</label>
                            },
                            render: () =>
                                <SecureTabPane
                                    user={this.props.user}
                                    authenticated={this.props.authenticated}
                                    checkIfAuthorized={this.props.checkIfAuthorized}
                                    permissionsRequired={["urn:aspen:power"]}
                                    component={<TimeMachineMetadataDetailView toast={this.props.toast} handleSetChangeEditor={this.handleSetChangeEditor} handleSetSave={this.handleSetSave} />}
                                    service={this.props.service}
                                    module={this.props.module}
                                    permissions={this.props.permissions}
                                    userPermissions={this.props.userPermissions}
                                />
                        },
                        {
                            menuItem: {
                                key: "time-machine-purge-tab",
                                content: <label>Time Machine Purger</label>
                            },
                            render: () =>
                                <SecureTabPane
                                    user={this.props.user}
                                    authenticated={this.props.authenticated}
                                    checkIfAuthorized={this.props.checkIfAuthorized}
                                    authInitialized={this.props.authInitialized}
                                    permissionsRequired={["urn:aspen:power","urn:tm:purge"]}
                                    component={<TimeMachinePurgeView toast={this.props.toast} />}
                                    service={this.props.service}
                                    module={this.props.module}
                                    permissions={this.props.permissions}
                                    userPermissions={this.props.userPermissions}
                                />
                        }
                    ]}
                />
            </>
        );
    }
}
