import React, {useMemo} from "react";
import {Button, Segment, Tab} from "semantic-ui-react";

export default function TimeMachineShowDetailPanel(props) {
    const copyToClipboard = payload => {
        navigator.clipboard.writeText(payload).then(() => {
            props.toast("Copied", `You have copied the show to your clipboard.`, "success");
        });
    }

    const panes = useMemo(() => {
        const updatedPanes = [];
        if (props.show) {
            updatedPanes.push({
                menuItem: "Show",
                render: () => (
                    <Segment>
                        <Button floated="left" onClick={() => copyToClipboard(JSON.stringify(props.show, null, 4))}>Copy Show</Button>
                        <br/><br/><br/>
                        <pre>{JSON.stringify(props.show, null, 4)}</pre>
                    </Segment>
                )
            });
        }

        if (props.extended_show) {
            updatedPanes.push({
                menuItem: "Extended Show",
                render: () => (
                    <Segment>
                        <Button floated="left" onClick={() => copyToClipboard(JSON.stringify(props.extended_show, null, 4))}>Copy Extended Show</Button>
                        <br/><br/><br/>
                        <pre>{JSON.stringify(props.extended_show, null, 4)}</pre>
                    </Segment>
                )
            });
        }

        return updatedPanes;
    }, [JSON.stringify(props.show), JSON.stringify(props.extended_show)]);

    return (
        <Tab
            panes={panes}
        />
    )
};
