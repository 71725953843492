import React from 'react';
import ReactTable from "react-table-v6";
import {Header, Grid, Button} from 'semantic-ui-react'
import TimeMachineMetaDataProvider from '../../../Services/TimeMachineMetaDataProvider';

class TimeMachinePurgeView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedKey: "",
            feed_ids: [],
            loading: false,
            currentFeedId:""
        };

        TimeMachineMetaDataProvider.init({baseURL: process.env.TIME_MACHINE_ADMIN_URL})

        this.getAllFeeds();
    }

    getAllFeeds = () => {
        this.setState({loading: true});

        TimeMachineMetaDataProvider.getAllInstances()
            .then(payload => {
                payload.json()
                    .then(feed_ids => {
                        if (Array.isArray(feed_ids)) {
                            let feeds = feed_ids.map(x => {return {"_id":x}});
                            this.setState({
                                feed_ids: feeds
                            });
                        } else {
                            this.props.toast("Error", "Error getting feed ids from Time Machine", "error");
                            this.setState({instances: []});
                        }
                    });
            }).catch(error => {
            console.error("TimeMachinePurgeView.getAllFeeds error: ", error);
            this.props.toast("Error", "Error getting feed ids from Time Machine", "error");
            this.setState({feed_ids: []});
        }).finally(() => {
            this.setState({loading: false});
        });
    };

    purgeFeed = () => {
        const r = confirm("You're about to delete a feed for Time Machine, this is a very dangerous operation as the data cannot be recovered. Are you sure?");
        if (r === false) {
            return;
        }
        TimeMachineMetaDataProvider.deleteFeed(this.state.currentFeedId['_id'])
            .then(resp => {
                resp.json()
                    .then(payload => {
                        if (payload.message) {
                            this.props.toast('Message', payload.message, 'success');

                        } else if (payload.error) {
                            this.props.toast('Message', payload.error, 'error');
                        }

                        this.getAllFeeds();
                    }).catch(error => {
                    this.props.toast("Error", "We could not delete the feed", "error");
                    console.error("TimeMachinePurgeView.purgeFeed error: ", error);
                });
            });
    };

    handleSelectInstance = instance => {
        this.setState({
            currentFeedId: instance,
            newClicked: true
        }, () => {
        });
    };

    render() {
        let that = this;
        return(
            <Grid style={{height: '100vh'}} >
                <Grid.Column className='masterContainer'>
                    <Button onClick={this.purgeFeed} color={"red"}>Delete Feed</Button>
                    <ReactTable
                        columns={[
                            { Header: 'Instance ID', accessor: '_id' }
                        ]}
                        data={this.state.feed_ids}
                        loading={this.state.loading}
                        filterable
                        defaultSorted={[
                            {
                                id: "_id",
                                desc: false
                            }
                        ]}
                        defaultFilterMethod={(filter, rows) => rows[filter.id].toString().toLowerCase().includes(filter.value.toString().toLowerCase())}
                        className="-striped -highlight"
                        getTrProps={(state, rowInfo, column, instance) => {
                            return {
                                onClick(event, handleOriginal) {
                                    that.setState({selectedKey: rowInfo.row._id });
                                    that.handleSelectInstance(rowInfo.original)
                                },
                                style: {
                                    background: rowInfo && rowInfo.row && that.state.selectedKey === rowInfo.row._id ? 'rgba(65, 83, 175, .5)' : '',
                                }
                            }
                        }}
                        style={{height: '75vh'}}
                    />
                </Grid.Column>
            </Grid>);
    }
}

export default TimeMachinePurgeView;
