import React, {useEffect, useMemo, useState} from "react";
import {MaterialReactTable} from "material-react-table";
import {LocalizationProvider, DateTimePicker} from '@mui/x-date-pickers';
import {enUS} from "date-fns/locale";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFnsV3";
import {useHistory, useLocation} from "react-router";
import {Button, Divider, Form, Grid, Icon, Select, Message, Modal, Checkbox, Container} from "semantic-ui-react";
import TimeMachineDataProvider from "../../../Services/TimeMachineDataProvider";
import TimeMachineShowDetailPanel from "./TimeMachineShowDetailPanel";

const TIME_ATTRS = ["show_timestamp", "last_update", "create_time"];
const DATE_DISPLAY_OPTIONS = {year: "numeric", month: "numeric", day: "numeric", hour: "numeric", minute: "numeric", second: "numeric"};

const DEFAULT_START_TIME = new Date(Date.now() - 1 * 24 * 60 * 60 * 1000);
const DEFAULT_END_TIME = new Date(Date.now() + 7 * 24 * 60 * 60 * 1000);

export default function TimeMachineShowSearchMasterDetailView(props) {
    const [allInstances, setAllInstances] = useState([]);
    const [selectedInstances, setSelectedInstances] = useState([]);
    const [selectedShowIds, setSelectedShowIds] = useState([]);
    const [selectedExternalIds, setSelectedExternalIds] = useState([]);
    const [selectedTitleIds, setSelectedTitleIds] = useState([]);
    const [selectedTitleNames, setSelectedTitleNames] = useState([]);
    const [selectedProgramHashes, setSelectedProgramHashes] = useState([]);
    const [selectedPropEps, setSelectedPropEps] = useState([]);
    const [selectedPaids, setSelectedPaids] = useState([]);
    const [selectedSeasonIds, setSelectedSeasonIds] = useState([]);
    const [selectedSeasonHashes, setSelectedSeasonHashes] = useState([]);
    const [selectedSeasonNames, setSelectedSeasonNames] = useState([]);
    const [selectedSeriesIds, setSelectedSeriesIds] = useState([]);
    const [selectedSeriesNames, setSelectedSeriesNames] = useState([]);
    const [selectedSeriesHashes, setSelectedSeriesHashes] = useState([]);
    const [selectedKeywords, setSelectedKeywords] = useState([]);
    const [selectedStartTime, setSelectedStartTime] = useState(DEFAULT_START_TIME);
    const [selectedEndTime, setSelectedEndTime] = useState(DEFAULT_END_TIME);
    const [selectedTimeAttribute, setSelectedTimeAttribute] = useState("show_timestamp");

    const [instanceOptions, setInstanceOptions] = useState([]);
    const [showIdOptions, setShowIdOptions] = useState([]);
    const [externalIdOptions, setExternalIdOptions] = useState([]);
    const [titleIdOptions, setTitleIdOptions] = useState([]);
    const [titleNameOptions, setTitleNameOptions] = useState([]);
    const [programHashOptions, setProgramHashOptions] = useState([]);
    const [propEpOptions, setPropEpOptions] = useState([]);
    const [paidOptions, setPaidOptions] = useState([]);
    const [seasonIdOptions, setSeasonIdOptions] = useState([]);
    const [seasonHashOptions, setSeasonHashOptions] = useState([]);
    const [seasonNameOptions, setSeasonNameOptions] = useState([]);
    const [seriesIdOptions, setSeriesIdOptions] = useState([]);
    const [seriesNameOptions, setSeriesNameOptions] = useState([]);
    const [seriesHashOptions, setSeriesHashOptions] = useState([]);
    const [keywordOptions, setKeywordOptions] = useState([]);

    const [matchAnyInstances, setMatchAnyInstances] = useState(true);
    const [matchAnyShowIds, setMatchAnyShowIds] = useState(true);
    const [matchAnyExternalIds, setMatchAnyExternalIds] = useState(true);
    const [matchAnyTitleIds, setMatchAnyTitleIds] = useState(true);
    const [matchAnyTitleNames, setMatchAnyTitleNames] = useState(true);
    const [matchAnyProgramHashes, setMatchAnyProgramHashes] = useState(true);
    const [matchAnyPropEps, setMatchAnyPropEps] = useState(true);
    const [matchAnyPaids, setMatchAnyPaids] = useState(true);
    const [matchAnySeasonIds, setMatchAnySeasonIds] = useState(true);
    const [matchAnySeasonHashes, setMatchAnySeasonHashes] = useState(true);
    const [matchAnySeasonNames, setMatchAnySeasonNames] = useState(true);
    const [matchAnySeriesIds, setMatchAnySeriesIds] = useState(true);
    const [matchAnySeriesNames, setMatchAnySeriesNames] = useState(true);
    const [matchAnySeriesHashes, setMatchAnySeriesHashes] = useState(true);
    const [matchAnyKeywords, setMatchAnyKeywords] = useState(true);

    const [allowReprocessing, setAllowReprocessing] = useState(true);
    const [allowRepublishing, setAllowRepublishing] = useState(true);

    const [reprocessClicked, setReprocessClicked] = useState(false);
    const [republishClicked, setRepublishClicked] = useState(false);

    const [reprocessing, setReprocessing] = useState(false);
    const [republishing, setRepublishing] = useState(false);

    const [loading, setLoading] = useState(false);
    const [searchEnabled, setSearchEnabled] = useState(false);
    const [searchClicked, setSearchClicked] = useState(false);

    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [replayLink, setReplayLink] = useState("");

    const [shows, setShows] = useState([]);
    const [totalShows, setTotalShows] = useState(0);
    const [expanded, setExpanded] = useState({});

    const history = useHistory();
    const {search} = useLocation();

    useEffect(function loadFromSearchParams() {
        const searchParams = new URLSearchParams(search);
        let doSearch = false;

        if (searchParams.has("instance")) {
            let instances = searchParams.get("instance").split(",");

            if (_.difference(instances, selectedInstances).length > 0 || _.difference(selectedInstances, instances).length > 0) {
                const currentInstances = new Set();
                for (const instanceOption of instanceOptions) {
                    currentInstances.add(instanceOption.value);
                }

                const updatedInstanceOptions = [];
                for (const instance of Array.from(currentInstances)) {
                    updatedInstanceOptions.push({key: instance, text: instance, value: instance});
                }

                for (const instance of allInstances) {
                    if (!currentInstances.has(instance)) {
                        updatedInstanceOptions.push({key: instance, text: instance, value: instance});
                    }
                }

                const updatedMatchAnyInstances = !searchParams.get("excludeInstances");
                setMatchAnyInstances(updatedMatchAnyInstances);

                console.log("TimeMachineShowSearchMasterDetailView.loadFromURLSearchParams: updated instance options: ", updatedInstanceOptions, "instances: ", instances);
                setInstanceOptions(updatedInstanceOptions);
                setSelectedInstances(instances);
                doSearch = true;
            }
        }

        if (searchParams.has("showId")) {
            let showIds = searchParams.get("showId").split(",");
            if (_.difference(showIds, selectedShowIds).length > 0 || _.difference(selectedShowIds, showIds).length > 0) {
                const updatedShowIdOptions = [];
                for (const showId of showIds) {
                    updatedShowIdOptions.push({key: showId, text: showId, value: showId});
                }

                const updatedMatchAnyShowIds = !searchParams.get("excludeShowIds");
                setMatchAnyShowIds(updatedMatchAnyShowIds);

                setShowIdOptions(updatedShowIdOptions);
                setSelectedShowIds(showIds);
                doSearch = true;
            }
        }

        if (searchParams.has("externalId")) {
            let externalIds = searchParams.get("externalId").split(",");

            if (_.difference(externalIds, selectedExternalIds).length > 0 || _.difference(selectedExternalIds, externalIds).length > 0) {
                const updatedExternalIdOptions = [];
                for (const externalId of externalIds) {
                    updatedExternalIdOptions.push({key: externalId, text: externalId, value: externalId});
                }

                const updatedMatchAnyExternalIds = !searchParams.get("excludeExternalIds");
                setMatchAnyExternalIds(updatedMatchAnyExternalIds);

                setExternalIdOptions(updatedExternalIdOptions);
                setSelectedExternalIds(externalIds);
                doSearch = true;
            }
        }

        if (searchParams.has("titleId")) {
            let titleIds = searchParams.get("titleId").split(",");
            if (_.difference(titleIds, selectedTitleIds).length > 0 || _.difference(selectedTitleIds, titleIds).length > 0) {
                const updatedTitleIdOptions = [];
                for (const titleId of titleIds) {
                    updatedTitleIdOptions.push({key: titleId, text: titleId, value: titleId});
                }

                const updatedMatchAnyTitleIds = !searchParams.get("excludeTitleIds");
                setMatchAnyTitleIds(updatedMatchAnyTitleIds);

                setTitleIdOptions(updatedTitleIdOptions);
                setSelectedTitleIds(titleIds);
                doSearch = true;
            }
        }

        if (searchParams.has("propEp")) {
            let propEps = searchParams.get("propEp").split(",");
            if (_.difference(propEps, selectedPropEps).length > 0 || _.difference(selectedPropEps, propEps).length > 0) {
                const updatedPropEpOptions = [];
                for (const propEp of propEps) {
                    updatedPropEpOptions.push({key: propEp, text: propEp, value: propEp});
                }

                const updatedMatchAnyPropEps = !searchParams.get("excludePropEps");
                setMatchAnyPropEps(updatedMatchAnyPropEps);

                setPropEpOptions(updatedPropEpOptions);
                setSelectedPropEps(propEps);
                doSearch = true;
            }
        }

        if (searchParams.has("paid")) {
            let paids = searchParams.get("paid").split(",");
            if (_.difference(paids, selectedPaids).length > 0 || _.difference(selectedPaids, paids).length > 0) {
                const updatedPaidOptions = [];
                for (const paid of paids) {
                    updatedPaidOptions.push({key: paid, text: paid, value: paid});
                }

                const updatedMatchAnyPaids = !searchParams.get("excludePaids");
                setMatchAnyPaids(updatedMatchAnyPaids);

                setPaidOptions(updatedPaidOptions);
                setSelectedPaids(paids);
                doSearch = true;
            }
        }

        if (searchParams.has("title")) {
            let titleNames = searchParams.get("title").split(",");
            if (_.difference(titleNames, selectedTitleNames).length > 0 || _.difference(selectedTitleNames, titleNames).length > 0) {
                const updatedTitleNameOptions = [];
                for (const titleName of titleNames) {
                    updatedTitleNameOptions.push({key: titleName, text: titleName, value: titleName});
                }

                const updatedMatchAnyTitleNames = !searchParams.get("excludeTitleNames");
                setMatchAnyTitleNames(updatedMatchAnyTitleNames);

                setTitleNameOptions(updatedTitleNameOptions);
                setSelectedTitleNames(titleNames);
                doSearch = true;
            }
        }

        if (searchParams.has("programHash")) {
            let programHashes = searchParams.get("programHash").split(",");
            if (_.difference(programHashes, selectedProgramHashes).length > 0 || _.difference(selectedProgramHashes, programHashes).length > 0) {
                const updatedProgramHashOptions = [];
                for (const programHash of programHashes) {
                    updatedProgramHashOptions.push({key: programHash, text: programHash, value: programHash});
                }

                const updatedMatchAnyProgramHashes = !searchParams.get("excludeProgramHashes");
                setMatchAnyProgramHashes(updatedMatchAnyProgramHashes);

                setProgramHashOptions(updatedProgramHashOptions);
                setSelectedProgramHashes(programHashes);
                doSearch = true;
            }
        }

        if (searchParams.has("seasonId")) {
            let seasonIds = searchParams.get("seasonId").split(",");
            if (_.difference(seasonIds, selectedSeasonIds).length > 0 || _.difference(selectedSeasonIds, seasonIds).length > 0) {
                const updatedSeasonIdOptions = [];
                for (const seasonId of seasonIds) {
                    updatedSeasonIdOptions.push({key: seasonId, text: seasonId, value: seasonId});
                }

                const updatedMatchAnySeasonIds = !searchParams.get("excludeSeasonIds");
                setMatchAnySeasonIds(updatedMatchAnySeasonIds);

                setSeasonIdOptions(updatedSeasonIdOptions);
                setSelectedSeasonIds(seasonIds);
                doSearch = true;
            }
        }

        if (searchParams.has("seasonHash")) {
            let seasonHashes = searchParams.get("seasonHash").split(",");
            if (_.difference(seasonHashes, selectedSeasonHashes).length > 0 || _.difference(selectedSeasonHashes, seasonHashes).length > 0) {
                const updatedSeasonHashOptions = [];
                for (const seasonHash of seasonHashes) {
                    updatedSeasonHashOptions.push({key: seasonHash, text: seasonHash, value: seasonHash});
                }

                const updatedMatchAnySeasonHashes = !searchParams.get("excludeSeasonHashes");
                setMatchAnySeasonHashes(updatedMatchAnySeasonHashes);

                setSeasonHashOptions(updatedSeasonHashOptions);
                setSelectedSeasonHashes(seasonHashes);
                doSearch = true;
            }
        }

        if (searchParams.has("seasonName")) {
            let seasonNames = searchParams.get("seasonName").split(",");
            if (_.difference(seasonNames, selectedSeasonNames).length > 0 || _.difference(selectedSeasonNames, seasonNames).length > 0) {
                const updatedSeasonNameOptions = [];
                for (const seasonName of seasonNames) {
                    updatedSeasonNameOptions.push({key: seasonName, text: seasonName, value: seasonName});
                }

                const updatedMatchAnySeasonNames = !searchParams.get("excludeSeasonNames");
                setMatchAnySeasonNames(updatedMatchAnySeasonNames);

                setSeasonNameOptions(updatedSeasonNameOptions);
                setSelectedSeasonNames(seasonNames);
                doSearch = true;
            }
        }

        if (searchParams.has("seriesId")) {
            let seriesIds = searchParams.get("seriesId").split(",");
            if (_.difference(seriesIds, selectedSeriesIds).length > 0 || _.difference(selectedSeriesIds, seriesIds).length > 0) {
                const updatedSeriesIdOptions = [];
                for (const seriesId of seriesIds) {
                    updatedSeriesIdOptions.push({key: seriesId, text: seriesId, value: seriesId});
                }

                const updatedMatchAnySeriesIds = !searchParams.get("excludeSeriesIds");
                setMatchAnySeriesIds(updatedMatchAnySeriesIds);

                setSeriesIdOptions(updatedSeriesIdOptions);
                setSelectedSeriesIds(seriesIds);
                doSearch = true;
            }
        }

        if (searchParams.has("seriesHash")) {
            let seriesHashes = searchParams.get("seriesHash").split(",");
            if (_.difference(seriesHashes, selectedSeriesHashes).length > 0 || _.difference(selectedSeriesHashes, seriesHashes).length > 0) {
                const updatedSeriesHashOptions = [];
                for (const seriesHash of seriesHashes) {
                    updatedSeriesHashOptions.push({key: seriesHash, text: seriesHash, value: seriesHash});
                }

                const updatedMatchAnySeriesHashes = !searchParams.get("excludeSeriesHashes");
                setMatchAnySeriesHashes(updatedMatchAnySeriesHashes);

                setSeriesHashOptions(updatedSeriesHashOptions);
                setSelectedSeriesHashes(seriesHashes);
                doSearch = true;
            }
        }

        if (searchParams.has("seriesName")) {
            let seriesNames = searchParams.get("seriesName").split(",");

            const updatedSeriesNameOptions = [];
            for (const seriesName of seriesNames) {
                updatedSeriesNameOptions.push({key: seriesName, text: seriesName, value: seriesName});
            }

            const updatedMatchAnySeriesNames = !searchParams.get("excludeSeriesNames");
            setMatchAnySeriesNames(updatedMatchAnySeriesNames);

            setSeriesNameOptions(updatedSeriesNameOptions);
            setSelectedSeriesNames(seriesNames);
            doSearch = true;
        }

        if (searchParams.has("keyword")) {
            let keywords = searchParams.get("keyword").split(",");
            if (_.difference(keywords, selectedKeywords).length > 0 || _.difference(selectedKeywords, keywords).length > 0) {
                const updatedKeywordOptions = [];
                for (const keyword of keywords) {
                    updatedKeywordOptions.push({key: keyword, text: keyword, value: keyword});
                }

                const updatedMatchAnyKeywords = !searchParams.get("excludeKeywords");
                setMatchAnyKeywords(updatedMatchAnyKeywords);

                setKeywordOptions(updatedKeywordOptions);
                setSelectedKeywords(keywords);
                doSearch = true;
            }
        }

        if (searchParams.has("startTime")) {
            const startTime = parseInt(searchParams.get("startTime"));
            if (!selectedStartTime || selectedStartTime.getTime() !== startTime) {
                setSelectedStartTime(new Date(startTime));
                doSearch = true;
            }

        }

        if (searchParams.has("endTime")) {
            const endTime = parseInt(searchParams.get("endTime"));
            if (!selectedEndTime || selectedEndTime.getTime() !== endTime) {
                setSelectedEndTime(new Date(endTime));
                doSearch = true;
            }
        }

        if (history.location.search.length > 0) {
            setSearchEnabled(true);
            searchShows();
        }
    }, [JSON.stringify(search)]);

    useEffect(() => {
        setSearchEnabled(selectedInstances.length > 0 || selectedShowIds.length > 0 || selectedExternalIds.length > 0 || selectedTitleIds.length > 0 || selectedSeriesIds.length > 0 || selectedPropEps.length > 0 || selectedPaids.length > 0);
    }, [JSON.stringify(selectedInstances), JSON.stringify(selectedShowIds), JSON.stringify(selectedExternalIds), JSON.stringify(selectedTitleIds), JSON.stringify(selectedSeriesIds), JSON.stringify(selectedPropEps), JSON.stringify(selectedPaids)]);

    TimeMachineDataProvider.init(process.env.TIME_MACHINE_ADMIN_URL);

    useEffect( () => {
        const allInstances = new Set();
        TimeMachineDataProvider.getInstances().then(response => {
            if (Array.isArray(response)) {
                response.sort();
                for (const instance of response) {
                    allInstances.add(instance);
                }
            }
        }).finally(() => {
            console.log("setting all instances: ", allInstances);
            setAllInstances(Array.from(allInstances));

            const updatedInstanceOptions = [];
            for (const instance of Array.from(allInstances)) {
                updatedInstanceOptions.push({key: instance, text: instance, value: instance});
            }
            console.log("setting updated instance options: ", updatedInstanceOptions);
            setInstanceOptions(updatedInstanceOptions);
        });
    }, []);

    const timeAttributeOptions = useMemo(() => {
        return TIME_ATTRS.map(attr => {
            return {key: attr, text: attr, value: attr};
        });
    }, []);

    const clearFilters = () => {
        setSelectedInstances([]);
        setSelectedShowIds([]);
        setSelectedExternalIds([]);
        setSelectedTitleIds([]);
        setSelectedPropEps([]);
        setSelectedPaids([]);
        setSelectedTitleNames([]);
        setSelectedProgramHashes([]);
        setSelectedSeasonIds([]);
        setSelectedSeasonHashes([]);
        setSelectedSeasonNames([]);
        setSelectedSeriesIds([]);
        setSelectedSeriesNames([]);
        setSelectedKeywords([]);
        setMatchAnyInstances(true);
        setMatchAnyShowIds(true);
        setMatchAnyExternalIds(true);
        setMatchAnyTitleIds(true);
        setMatchAnyPropEps(true);
        setMatchAnyPaids(true);
        setMatchAnyTitleNames(true);
        setMatchAnyProgramHashes(true);
        setMatchAnySeasonIds(true);
        setMatchAnySeasonNames(true);
        setMatchAnySeasonHashes(true);
        setMatchAnySeriesIds(true);
        setMatchAnySeriesNames(true);
        setMatchAnySeriesHashes(true);
        setMatchAnyKeywords(true);
        setSelectedStartTime(new Date(DEFAULT_START_TIME));
        setSelectedEndTime(new Date(DEFAULT_END_TIME));
        setSuccessMessage("");
    };

    const searchShows = () => {
        setAllowRepublishing(false);
        setAllowReprocessing(false);
        if (!searchEnabled) return;

        const urlSearchParams = new URLSearchParams({
            ...selectedInstances.length > 0 && {instance: selectedInstances},
            ...matchAnyInstances === false && {excludeInstances: true},
            ...selectedShowIds.length > 0 && {showId: selectedShowIds},
            ...matchAnyShowIds === false && {excludeShowIds: true},
            ...selectedExternalIds.length > 0 && {externalId: selectedExternalIds},
            ...matchAnyExternalIds === false && {excludeExternalIds: true},
            ...selectedTitleIds.length > 0 && {titleId: selectedTitleIds},
            ...matchAnyTitleIds === false && {excludeTitleIds: true},
            ...selectedSeasonIds.length > 0 && {seasonId: selectedSeasonIds},
            ...matchAnySeasonIds === false && {excludeSeasonIds: true},
            ...selectedSeriesIds.length > 0 && {seriesId: selectedSeriesIds},
            ...matchAnySeriesIds === false && {excludeSeriesIds: true},
            ...selectedPropEps.length > 0 && {propEp: selectedPropEps},
            ...matchAnyPropEps === false && {excludePropEps: true},
            ...selectedPaids.length > 0 && {paid: selectedPaids},
            ...matchAnyPaids === false && {excludePaids: true},
            ...selectedTitleNames.length > 0 && {titleName: selectedTitleNames},
            ...matchAnyTitleNames === false && {excludeTitleNames: true},
            ...selectedProgramHashes.length > 0 && {programHash: selectedProgramHashes},
            ...matchAnyProgramHashes === false && {excludeProgramHashes: true},
            ...selectedSeasonNames.length > 0 && {seasonName: selectedSeasonNames},
            ...matchAnySeasonNames === false && {excludeSeasonNames: true},
            ...selectedSeasonHashes.length > 0 && {seasonHash: selectedSeasonHashes},
            ...matchAnySeasonHashes === false && {excludeSeasonHashes: true},
            ...selectedSeriesNames.length > 0 && {seasonNames: selectedSeriesNames},
            ...matchAnySeriesNames === false && {excludeSeriesNames: true},
            ...selectedSeriesHashes.length > 0 && {seasonNames: selectedSeriesHashes},
            ...matchAnySeriesHashes === false && {excludeSeriesHashes: true},
            ...selectedKeywords.length > 0 && {keyword: selectedKeywords},
            ...matchAnyKeywords === false && {excludeKeywords: true},
            ...selectedStartTime !== null && {startTime: parseInt(selectedStartTime.getTime())},
            ...selectedEndTime !== null && { endTime: parseInt(selectedEndTime.getTime())}
        });

        history.replace({ pathname: location.pathname, search: urlSearchParams.toString() });

        setLoading(true);
        setSearchClicked(true);
        setSuccessMessage("");
        TimeMachineDataProvider.searchShows(selectedStartTime, selectedEndTime, selectedTimeAttribute, selectedInstances, selectedShowIds, selectedExternalIds, selectedTitleIds, selectedPropEps, selectedPaids, selectedTitleNames, selectedProgramHashes, selectedSeasonIds, selectedSeasonHashes, selectedSeasonNames, selectedSeriesIds, selectedSeriesHashes, selectedSeriesNames, selectedKeywords, matchAnyInstances, matchAnyShowIds, matchAnyExternalIds, matchAnyTitleIds, matchAnyPropEps, matchAnyPaids, matchAnyTitleNames, matchAnyProgramHashes, matchAnySeasonIds, matchAnySeasonHashes, matchAnySeasonNames, matchAnySeriesIds, matchAnySeriesHashes,  matchAnySeriesNames, matchAnyKeywords).then(data => {
            if (data.hasOwnProperty("error")) {
                console.error(JSON.stringify(data));
                setShows([]);
                setErrorMessage(data.error);
                return;
            }

            if (data.hasOwnProperty("results")) {
                setShows(data.results);
                setTotalShows(data.total);
                if (data.hasOwnProperty("total") && data.total > 0) {
                    setAllowRepublishing(true);
                    setAllowReprocessing(true);
                }
            }
        }).finally(() => {
            setLoading(false);
        });
    };


    const onConfirmReprocess = () => {
        setReprocessClicked(false);
        setRepublishClicked(false);
        setReprocessing(true);
        setSuccessMessage("");
        const start = Date.now();

        TimeMachineDataProvider.replayShow(parseInt(selectedStartTime.getTime() / 1000), parseInt(selectedEndTime.getTime() / 1000), selectedTimeAttribute, selectedInstances, selectedShowIds, selectedExternalIds, selectedTitleIds, selectedPropEps, selectedPaids, selectedTitleNames, selectedProgramHashes, selectedSeasonIds, selectedSeasonHashes, selectedSeasonNames, selectedSeriesIds, selectedSeriesHashes, selectedSeriesNames, selectedKeywords, matchAnyInstances, matchAnyShowIds, matchAnyExternalIds, matchAnyTitleIds, matchAnyPropEps, matchAnyPaids, matchAnyTitleNames, matchAnyProgramHashes, matchAnySeasonIds, matchAnySeasonHashes, matchAnySeasonNames, matchAnySeriesIds, matchAnySeriesHashes,  matchAnySeriesNames, matchAnyKeywords, true).then(response => {
            console.log("TimeMachineShowSearchMasterDetailView.onConfirmReprocess: response: ", response);

            if (response.hasOwnProperty("error")) {
                console.error(response.error);
                props.toast("Error", response.error, "error");
                return;
            }

            setSuccessMessage(JSON.stringify(response, null, 4));
            const urlSearchParams = new URLSearchParams({
                ...selectedInstances.length > 0 && {instance: selectedInstances},
                ...matchAnyInstances === false && {excludeInstances: true},
                ...selectedShowIds.length > 0 && {showId: selectedShowIds},
                ...matchAnyShowIds === false && {excludeShowIds: true},
                ...selectedExternalIds.length > 0 && {externalId: selectedExternalIds},
                ...matchAnyExternalIds === false && {excludeExternalIds: true},
                ...selectedTitleIds.length > 0 && {titleId: selectedTitleIds},
                ...matchAnyTitleIds === false && {excludeTitleIds: true},
                ...selectedPaids.length > 0 && {paid: selectedPaids},
                ...matchAnyPaids === false && {excludePaids: true},
                startTime: start,
                endTime: start + 24 * 60 * 60,
                status: ["REPLAYING", "REPLAY_COMPLETED", "FAILED"],
                ingester: ["SYNAPSE"]
            });
            const updatedReplayLink = `/TimeMachineAudit?${urlSearchParams.toString()}`;

            setReplayLink(updatedReplayLink);
            setIsModalVisible(true);
            setAllowReprocessing(true);
            setAllowRepublishing(true);
        }).finally(() => {
            setReprocessing(false);
        });
    };

    const onConfirmRepublish = () => {
        setRepublishClicked(false);
        setReprocessClicked(false);
        setRepublishing(true);
        setSuccessMessage("");
        const start = Date.now();

        TimeMachineDataProvider.replayShow(parseInt(selectedStartTime.getTime() / 1000), parseInt(selectedEndTime.getTime() / 1000), selectedTimeAttribute, selectedInstances, selectedShowIds, selectedExternalIds, selectedTitleIds, selectedPropEps, selectedPaids, selectedTitleNames, selectedProgramHashes, selectedSeasonIds, selectedSeasonHashes, selectedSeasonNames, selectedSeriesIds, selectedSeriesHashes, selectedSeriesNames, selectedKeywords, matchAnyInstances, matchAnyShowIds, matchAnyExternalIds, matchAnyTitleIds, matchAnyPropEps, matchAnyPaids, matchAnyTitleNames, matchAnyProgramHashes, matchAnySeasonIds, matchAnySeasonHashes, matchAnySeasonNames, matchAnySeriesIds, matchAnySeriesHashes, matchAnySeriesNames, matchAnyKeywords, false).then(response => {
            console.log("TimeMachineShowSearchMasterDetailView.onConfirmRepublish: response: ", response);

            if (response.hasOwnProperty("error")) {
                console.error(response.error);
                props.toast("Error", response.error, "error");
                return;
            }

            const urlSearchParams = new URLSearchParams({
                ...selectedInstances.length > 0 && {instance: selectedInstances},
                ...matchAnyInstances === false && {excludeInstances: true},
                ...selectedShowIds.length > 0 && {showId: selectedShowIds},
                ...matchAnyShowIds === false && {excludeShowIds: true},
                ...selectedExternalIds.length > 0 && {externalId: selectedExternalIds},
                ...matchAnyExternalIds === false && {excludeExternalIds: true},
                ...selectedTitleIds.length > 0 && {titleId: selectedTitleIds},
                ...matchAnyTitleIds === false && {excludeTitleIds: true},
                ...selectedPaids.length > 0 && {paid: selectedPaids},
                ...matchAnyPaids === false && {excludePaids: true},
                startTime: start,
                endTime: start + 24 * 60 * 60,
                status: ["REPLAYING", "REPLAY_COMPLETED", "FAILED"],
                ingester: ["SYNAPSE"]
            }).toString();

            const updatedReplayLink = `/TimeMachineAudit?${urlSearchParams}`;

            setReplayLink(updatedReplayLink);
            setIsModalVisible(true);

            setSuccessMessage(JSON.stringify(response, null, 4));

        }).finally(() => {
            setRepublishing(false);
        });
    };

    return (
        <Grid>
            <Grid.Row>
                <Grid.Column width={4} className="masterContainer" style={{maxHeight: "95vh", overflowY: "auto", overflowX: "hidden"}}>
                    <Form widths={16}>
                        <Form.Field>
                            <Button disabled={!searchEnabled} color="blue" onClick={searchShows} fluid><Icon name="search" />&nbsp;Search</Button>
                        </Form.Field>
                        <Form.Field>
                            <Form.Field>
                                <Button fluid color="black" onClick={clearFilters}><Icon name="trash" />&nbsp;Clear Filters</Button>
                            </Form.Field>
                        </Form.Field>
                        <Modal
                            size="large"
                            visible={isModalVisible || reprocessClicked || republishClicked}
                            onClose={() => setIsModalVisible(false)}
                            closeIcon
                            style={{position: "relative"}}
                            dimmer="inverted"
                            trigger={
                                <div>
                                    <Form.Field>
                                        <Button fluid color="green" onClick={() => setReprocessClicked(true)} disabled={!allowReprocessing} icon><Icon name={reprocessing ? "spinner" : "repeat"} />&nbsp;Reprocess</Button>
                                    </Form.Field>
                                    <Form.Field>
                                        <Button fluid color="green" onClick={() => setRepublishClicked(true)} disabled={!allowRepublishing} icon><Icon name={republishing ? "spinner" : "repeat"} />&nbsp;Republish</Button>
                                    </Form.Field>
                                </div>
                            }
                        >
                            <Modal.Content>
                                {
                                    reprocessing ?
                                        <Message color="blue" icon><Icon name="spinner" loading />Reprocessing</Message> :
                                    republishing ?
                                        <Message color="blue" icon><Icon name="spinner" loading />Republishing</Message> :
                                    republishClicked || reprocessClicked ?
                                        <Container>
                                            Are you sure you want to {republishClicked ? "republish" : "reprocess"} {totalShows} shows?
                                        </Container>
                                         :
                                        <Message icon color="green">
                                            <Icon name="check" />
                                            <Message.Content>
                                                <pre>{successMessage}</pre>
                                            </Message.Content>
                                        </Message>
                                }
                            </Modal.Content>
                            {
                                republishClicked || reprocessClicked ?
                                    <Modal.Actions>
                                        <Button
                                            primary
                                            onClick={
                                                () => {
                                                    republishClicked ? onConfirmRepublish() : onConfirmReprocess()
                                                }
                                            }
                                        >Confirm</Button>
                                        <Button
                                            secondary
                                            onClick={
                                                () => {
                                                    setReprocessClicked(false);
                                                    setRepublishClicked(false);
                                                    setIsModalVisible(false);
                                                }
                                            }
                                        >Cancel</Button>
                                    </Modal.Actions> :
                                    <Modal.Actions>
                                        <Button primary fluid as="a" href={replayLink} target="_blank" content="View in Audit"/>
                                    </Modal.Actions>
                            }
                        </Modal>

                        <Divider horizontal>Indexed Fields</Divider>
                        <Form.Field
                            label={
                                <Grid>
                                    <Grid.Column width={8} style={{paddingBottom: 0}}>
                                        <Form.Field>
                                            <b>Instance</b>
                                        </Form.Field>
                                    </Grid.Column>
                                    <Grid.Column style={{paddingBottom: 0}}>
                                        <Form.Group>
                                            <Form.Field>
                                                <Checkbox
                                                    label="Include"
                                                    checked={matchAnyInstances === true}
                                                    onClick={() => setMatchAnyInstances(true)}
                                                    radio
                                                />
                                            </Form.Field>
                                            <Form.Field>
                                                <Checkbox
                                                    label="Exclude"
                                                    checked={matchAnyInstances === false}
                                                    onClick={() => setMatchAnyInstances(false)}
                                                    radio
                                                />
                                            </Form.Field>
                                        </Form.Group>
                                    </Grid.Column>
                                </Grid>
                            }
                            control={Select}
                            options={instanceOptions}
                            multiple
                            search
                            allowAdditions
                            value={selectedInstances}
                            onChange={(event, {value}) => setSelectedInstances(value)}
                            onAddItem={(event, {value}) => setInstanceOptions(instanceOptions.concat({key: value, text: value, value}))}
                            clearable
                        />
                        <Form.Field
                            label={
                                <Grid>
                                    <Grid.Column width={8} style={{paddingBottom: 0}}>
                                        <Form.Field>
                                            <b>Show ID</b>
                                        </Form.Field>
                                    </Grid.Column>
                                    <Grid.Column style={{paddingBottom: 0}}>
                                        <Form.Group>
                                            <Form.Field>
                                                <Checkbox
                                                    label="Include"
                                                    checked={matchAnyShowIds === true}
                                                    onClick={() => setMatchAnyShowIds(true)}
                                                    radio
                                                />
                                            </Form.Field>
                                            <Form.Field>
                                                <Checkbox
                                                    label="Exclude"
                                                    checked={matchAnyShowIds === false}
                                                    onClick={() => setMatchAnyShowIds(false)}
                                                    radio
                                                />
                                            </Form.Field>
                                        </Form.Group>
                                    </Grid.Column>
                                </Grid>
                            }
                            control={Select}
                            options={showIdOptions}
                            multiple
                            search
                            allowAdditions
                            value={selectedShowIds}
                            onChange={(event, {value}) => setSelectedShowIds(value)}
                            onAddItem={(event, {value}) => setShowIdOptions(showIdOptions.concat({key: value, text: value, value}))}
                            clearable
                        />
                        <Form.Field
                            label={
                                <Grid>
                                    <Grid.Column width={8} style={{paddingBottom: 0}}>
                                        <Form.Field>
                                            <b>External ID</b>
                                        </Form.Field>
                                    </Grid.Column>
                                    <Grid.Column style={{paddingBottom: 0}}>
                                        <Form.Group>
                                            <Form.Field>
                                                <Checkbox
                                                    label="Include"
                                                    checked={matchAnyExternalIds === true}
                                                    onClick={() => setMatchAnyExternalIds(true)}
                                                    radio
                                                />
                                            </Form.Field>
                                            <Form.Field>
                                                <Checkbox
                                                    label="Exclude"
                                                    checked={matchAnyExternalIds === false}
                                                    onClick={() => setMatchAnyExternalIds(false)}
                                                    radio
                                                />
                                            </Form.Field>
                                        </Form.Group>
                                    </Grid.Column>
                                </Grid>
                            }
                            control={Select}
                            options={externalIdOptions}
                            multiple
                            search
                            allowAdditions
                            value={selectedExternalIds}
                            onChange={(event, {value}) => setSelectedExternalIds(value)}
                            onAddItem={(event, {value}) => setExternalIdOptions(externalIdOptions.concat({key: value, text: value, value}))}
                            clearable
                        />
                        <Form.Field
                            label={
                                <Grid>
                                    <Grid.Column width={8} style={{paddingBottom: 0}}>
                                        <Form.Field>
                                            <b>Title ID</b>
                                        </Form.Field>
                                    </Grid.Column>
                                    <Grid.Column style={{paddingBottom: 0}}>
                                        <Form.Group>
                                            <Form.Field>
                                                <Checkbox
                                                    label="Include"
                                                    checked={matchAnyTitleIds === true}
                                                    onClick={() => setMatchAnyTitleIds(true)}
                                                    radio
                                                />
                                            </Form.Field>
                                            <Form.Field>
                                                <Checkbox
                                                    label="Exclude"
                                                    checked={matchAnyTitleIds === false}
                                                    onClick={() => setMatchAnyTitleIds(false)}
                                                    radio
                                                />
                                            </Form.Field>
                                        </Form.Group>
                                    </Grid.Column>
                                </Grid>
                            }
                            control={Select}
                            options={titleIdOptions}
                            multiple
                            search
                            allowAdditions
                            value={selectedTitleIds}
                            onChange={(event, {value}) => setSelectedTitleIds(value)}
                            onAddItem={(event, {value}) => setTitleIdOptions(titleIdOptions.concat({key: value, text: value, value}))}
                            clearable
                        />
                        <Form.Field
                            label={
                                <Grid>
                                    <Grid.Column width={8} style={{paddingBottom: 0}}>
                                        <Form.Field>
                                            <b>PropEp</b>
                                        </Form.Field>
                                    </Grid.Column>
                                    <Grid.Column style={{paddingBottom: 0}}>
                                        <Form.Group>
                                            <Form.Field>
                                                <Checkbox
                                                    label="Include"
                                                    checked={matchAnyPropEps === true}
                                                    onClick={() => setMatchAnyPropEps(true)}
                                                    radio
                                                />
                                            </Form.Field>
                                            <Form.Field>
                                                <Checkbox
                                                    label="Exclude"
                                                    checked={matchAnyPropEps === false}
                                                    onClick={() => setMatchAnyPropEps(false)}
                                                    radio
                                                />
                                            </Form.Field>
                                        </Form.Group>
                                    </Grid.Column>
                                </Grid>
                            }
                            control={Select}
                            options={propEpOptions}
                            multiple
                            search
                            allowAdditions
                            value={selectedPropEps}
                            onChange={(event, {value}) => setSelectedPropEps(value)}
                            onAddItem={(event, {value}) => setPropEpOptions(propEpOptions.concat({key: value, text: value, value}))}
                            clearable
                        />
                        <Form.Field
                            label={
                                <Grid>
                                    <Grid.Column width={8} style={{paddingBottom: 0}}>
                                        <Form.Field>
                                            <b>Series ID</b>
                                        </Form.Field>
                                    </Grid.Column>
                                    <Grid.Column style={{paddingBottom: 0}}>
                                        <Form.Group>
                                            <Form.Field>
                                                <Checkbox
                                                    label="Include"
                                                    checked={matchAnySeriesIds === true}
                                                    onClick={() => setMatchAnySeriesIds(true)}
                                                    radio
                                                />
                                            </Form.Field>
                                            <Form.Field>
                                                <Checkbox
                                                    label="Exclude"
                                                    checked={matchAnySeriesIds === false}
                                                    onClick={() => setMatchAnySeriesIds(false)}
                                                    radio
                                                />
                                            </Form.Field>
                                        </Form.Group>
                                    </Grid.Column>
                                </Grid>
                            }
                            control={Select}
                            options={seriesIdOptions}
                            multiple
                            search
                            allowAdditions
                            value={selectedSeriesIds}
                            onChange={(event, {value}) => setSelectedSeriesIds(value)}
                            onAddItem={(event, {value}) => setSeriesIdOptions(seriesIdOptions.concat({key: value, text: value, value}))}
                            clearable
                        />
                        <Divider horizontal>Unindexed Fields</Divider>
                        <Form.Field
                            label={
                                <Grid>
                                    <Grid.Column width={8} style={{paddingBottom: 0}}>
                                        <Form.Field>
                                            <b>PAID</b>
                                        </Form.Field>
                                    </Grid.Column>
                                    <Grid.Column style={{paddingBottom: 0}}>
                                        <Form.Group>
                                            <Form.Field>
                                                <Checkbox
                                                    label="Include"
                                                    checked={matchAnyPaids === true}
                                                    onClick={() => setMatchAnyPaids(true)}
                                                    radio
                                                />
                                            </Form.Field>
                                            <Form.Field>
                                                <Checkbox
                                                    label="Exclude"
                                                    checked={matchAnyPaids === false}
                                                    onClick={() => setMatchAnyPaids(false)}
                                                    radio
                                                />
                                            </Form.Field>
                                        </Form.Group>
                                    </Grid.Column>
                                </Grid>
                            }
                            control={Select}
                            options={paidOptions}
                            multiple
                            search
                            allowAdditions
                            value={selectedPaids}
                            onChange={(event, {value}) => setSelectedPaids(value)}
                            onAddItem={(event, {value}) => setPaidOptions(paidOptions.concat({key: value, text: value, value}))}
                            clearable
                        />
                        <Form.Field
                            label={
                                <Grid>
                                    <Grid.Column width={8} style={{paddingBottom: 0}}>
                                        <Form.Field>
                                            <b>Title Name</b>
                                        </Form.Field>
                                    </Grid.Column>
                                    <Grid.Column style={{paddingBottom: 0}}>
                                        <Form.Group>
                                            <Form.Field>
                                                <Checkbox
                                                    label="Include"
                                                    checked={matchAnyTitleNames === true}
                                                    onClick={() => setMatchAnyTitleNames(true)}
                                                    radio
                                                />
                                            </Form.Field>
                                            <Form.Field>
                                                <Checkbox
                                                    label="Exclude"
                                                    checked={matchAnyTitleNames === false}
                                                    onClick={() => setMatchAnyTitleNames(false)}
                                                    radio
                                                />
                                            </Form.Field>
                                        </Form.Group>
                                    </Grid.Column>
                                </Grid>
                            }
                            control={Select}
                            options={titleNameOptions}
                            multiple
                            search
                            allowAdditions
                            value={selectedTitleNames}
                            onChange={(event, {value}) => setSelectedTitleNames(value)}
                            onAddItem={(event, {value}) => setTitleNameOptions(titleNameOptions.concat({key: value, text: value, value}))}
                            clearable
                        />
                        <Form.Field
                            label={
                                <Grid>
                                    <Grid.Column width={8} style={{paddingBottom: 0}}>
                                        <Form.Field>
                                            <b>Program Hash</b>
                                        </Form.Field>
                                    </Grid.Column>
                                    <Grid.Column style={{paddingBottom: 0}}>
                                        <Form.Group>
                                            <Form.Field>
                                                <Checkbox
                                                    label="Include"
                                                    checked={matchAnyProgramHashes === true}
                                                    onClick={() => setMatchAnyProgramHashes(true)}
                                                    radio
                                                />
                                            </Form.Field>
                                            <Form.Field>
                                                <Checkbox
                                                    label="Exclude"
                                                    checked={matchAnyProgramHashes === false}
                                                    onClick={() => setMatchAnyProgramHashes(false)}
                                                    radio
                                                />
                                            </Form.Field>
                                        </Form.Group>
                                    </Grid.Column>
                                </Grid>
                            }
                            control={Select}
                            options={programHashOptions}
                            multiple
                            search
                            allowAdditions
                            value={selectedProgramHashes}
                            onChange={(event, {value}) => setSelectedProgramHashes(value)}
                            onAddItem={(event, {value}) => setProgramHashOptions(programHashOptions.concat({key: value, text: value, value}))}
                            clearable
                        />
                        <Form.Field
                            label={
                                <Grid>
                                    <Grid.Column width={8} style={{paddingBottom: 0}}>
                                        <Form.Field>
                                            <b>Season ID</b>
                                        </Form.Field>
                                    </Grid.Column>
                                    <Grid.Column style={{paddingBottom: 0}}>
                                        <Form.Group>
                                            <Form.Field>
                                                <Checkbox
                                                    label="Include"
                                                    checked={matchAnySeasonIds === true}
                                                    onClick={() => setMatchAnySeasonIds(true)}
                                                    radio
                                                />
                                            </Form.Field>
                                            <Form.Field>
                                                <Checkbox
                                                    label="Exclude"
                                                    checked={matchAnySeasonIds === false}
                                                    onClick={() => setMatchAnySeasonIds(false)}
                                                    radio
                                                />
                                            </Form.Field>
                                        </Form.Group>
                                    </Grid.Column>
                                </Grid>
                            }
                            control={Select}
                            options={seasonIdOptions}
                            multiple
                            search
                            allowAdditions
                            value={selectedSeasonIds}
                            onChange={(event, {value}) => setSelectedSeasonIds(value)}
                            onAddItem={(event, {value}) => setSeasonIdOptions(seasonIdOptions.concat({key: value, text: value, value}))}
                            clearable
                        />
                        <Form.Field
                            label={
                                <Grid>
                                    <Grid.Column width={8} style={{paddingBottom: 0}}>
                                        <Form.Field>
                                            <b>Season Hash</b>
                                        </Form.Field>
                                    </Grid.Column>
                                    <Grid.Column style={{paddingBottom: 0}}>
                                        <Form.Group>
                                            <Form.Field>
                                                <Checkbox
                                                    label="Include"
                                                    checked={matchAnySeasonHashes === true}
                                                    onClick={() => setMatchAnySeasonHashes(true)}
                                                    radio
                                                />
                                            </Form.Field>
                                            <Form.Field>
                                                <Checkbox
                                                    label="Exclude"
                                                    checked={matchAnySeasonHashes === false}
                                                    onClick={() => setMatchAnySeasonHashes(false)}
                                                    radio
                                                />
                                            </Form.Field>
                                        </Form.Group>
                                    </Grid.Column>
                                </Grid>
                            }
                            control={Select}
                            options={seasonHashOptions}
                            multiple
                            search
                            allowAdditions
                            value={selectedSeasonHashes}
                            onChange={(event, {value}) => setSelectedSeasonHashes(value)}
                            onAddItem={(event, {value}) => setSeasonHashOptions(seasonHashOptions.concat({key: value, text: value, value}))}
                            clearable
                        />
                        <Form.Field
                            label={
                                <Grid>
                                    <Grid.Column width={8} style={{paddingBottom: 0}}>
                                        <Form.Field>
                                            <b>Season Name</b>
                                        </Form.Field>
                                    </Grid.Column>
                                    <Grid.Column style={{paddingBottom: 0}}>
                                        <Form.Group>
                                            <Form.Field>
                                                <Checkbox
                                                    label="Include"
                                                    checked={matchAnySeasonNames === true}
                                                    onClick={() => setMatchAnySeasonNames(true)}
                                                    radio
                                                />
                                            </Form.Field>
                                            <Form.Field>
                                                <Checkbox
                                                    label="Exclude"
                                                    checked={matchAnySeasonNames === false}
                                                    onClick={() => setMatchAnySeasonNames(false)}
                                                    radio
                                                />
                                            </Form.Field>
                                        </Form.Group>
                                    </Grid.Column>
                                </Grid>
                            }
                            control={Select}
                            options={seasonNameOptions}
                            multiple
                            search
                            allowAdditions
                            value={selectedSeasonNames}
                            onChange={(event, {value}) => setSelectedSeasonNames(value)}
                            onAddItem={(event, {value}) => setSeasonNameOptions(seasonNameOptions.concat({key: value, text: value, value}))}
                            clearable
                        />
                        <Form.Field
                            label={
                                <Grid>
                                    <Grid.Column width={8} style={{paddingBottom: 0}}>
                                        <Form.Field>
                                            <b>Series Hash</b>
                                        </Form.Field>
                                    </Grid.Column>
                                    <Grid.Column style={{paddingBottom: 0}}>
                                        <Form.Group>
                                            <Form.Field>
                                                <Checkbox
                                                    label="Include"
                                                    checked={matchAnySeriesHashes === true}
                                                    onClick={() => setMatchAnySeriesHashes(true)}
                                                    radio
                                                />
                                            </Form.Field>
                                            <Form.Field>
                                                <Checkbox
                                                    label="Exclude"
                                                    checked={matchAnySeriesHashes === false}
                                                    onClick={() => setMatchAnySeriesHashes(false)}
                                                    radio
                                                />
                                            </Form.Field>
                                        </Form.Group>
                                    </Grid.Column>
                                </Grid>
                            }
                            control={Select}
                            options={seriesHashOptions}
                            multiple
                            search
                            allowAdditions
                            value={selectedSeriesHashes}
                            onChange={(event, {value}) => setSelectedSeriesHashes(value)}
                            onAddItem={(event, {value}) => setSeriesHashOptions(seriesHashOptions.concat({key: value, text: value, value}))}
                            clearable
                        />
                        <Form.Field
                            label={
                                <Grid>
                                    <Grid.Column width={8} style={{paddingBottom: 0}}>
                                        <Form.Field>
                                            <b>Series Name</b>
                                        </Form.Field>
                                    </Grid.Column>
                                    <Grid.Column style={{paddingBottom: 0}}>
                                        <Form.Group>
                                            <Form.Field>
                                                <Checkbox
                                                    label="Include"
                                                    checked={matchAnySeriesNames === true}
                                                    onClick={() => setMatchAnySeriesNames(true)}
                                                    radio
                                                />
                                            </Form.Field>
                                            <Form.Field>
                                                <Checkbox
                                                    label="Exclude"
                                                    checked={matchAnySeriesNames === false}
                                                    onClick={() => setMatchAnySeriesNames(false)}
                                                    radio
                                                />
                                            </Form.Field>
                                        </Form.Group>
                                    </Grid.Column>
                                </Grid>
                            }
                            control={Select}
                            options={seriesNameOptions}
                            multiple
                            search
                            allowAdditions
                            value={selectedSeriesNames}
                            onChange={(event, {value}) => setSelectedSeriesNames(value)}
                            onAddItem={(event, {value}) => setSeriesNameOptions(seriesNameOptions.concat({key: value, text: value, value}))}
                            clearable
                        />
                        <Form.Field
                            label={
                                <Grid>
                                    <Grid.Column width={8} style={{paddingBottom: 0}}>
                                        <Form.Field>
                                            <b>Keyword</b>
                                        </Form.Field>
                                    </Grid.Column>
                                    <Grid.Column style={{paddingBottom: 0}}>
                                        <Form.Group>
                                            <Form.Field>
                                                <Checkbox
                                                    label="Include"
                                                    checked={matchAnyKeywords === true}
                                                    onClick={() => setMatchAnyKeywords(true)}
                                                    radio
                                                />
                                            </Form.Field>
                                            <Form.Field>
                                                <Checkbox
                                                    label="Exclude"
                                                    checked={matchAnyKeywords === false}
                                                    onClick={() => setMatchAnyKeywords(false)}
                                                    radio
                                                />
                                            </Form.Field>
                                        </Form.Group>
                                    </Grid.Column>
                                </Grid>
                            }
                            control={Select}
                            options={keywordOptions}
                            multiple
                            search
                            allowAdditions
                            value={selectedKeywords}
                            onChange={(event, {value}) => setSelectedKeywords(value)}
                            onAddItem={(event, {value}) => setKeywordOptions(keywordOptions.concat({key: value, text: value, value}))}
                            clearable
                        />
                        <Form.Field
                            label="Time Attribute"
                            control={Select}
                            options={timeAttributeOptions}
                            search
                            value={selectedTimeAttribute}
                            onChange={(event, {value}) => setSelectedTimeAttribute(value)}
                        />
                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enUS}>
                            <Form.Field>
                                <label>Start</label>
                                <DateTimePicker
                                    fullWidth
                                    value={selectedStartTime}
                                    onChange={value => setSelectedStartTime(value)}
                                    sx={{width: "100%"}}
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>End</label>
                                <DateTimePicker
                                    fullWidth
                                    value={selectedEndTime}
                                    onChange={value => setSelectedEndTime(value)}
                                    sx={{width: "100%"}}
                                />
                            </Form.Field>
                        </LocalizationProvider>
                    </Form>
                </Grid.Column>
                <Grid.Column width={12} className="masterContainer" style={{maxHeight: "95vh", overflowY: "auto", overflowX: "hidden" }}>
                    {
                        !searchClicked ?
                            <Message icon color="yellow">
                                <Icon name="info" />
                                <Message.Content>Enter search criteria into at least one indexed field, along with any number of unindexed fields, and click search to continue.</Message.Content>
                            </Message> :
                            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enUS}>
                                <MaterialReactTable
                                    columns={[
                                        {
                                            header: "Instance",
                                            accessorKey: "instance",
                                            filterSelectOptions: instanceOptions,
                                            filterVariant: "select",
                                            size: 100
                                        },
                                        {
                                            header: "Title Name",
                                            accessorKey: "title"
                                        },
                                        {
                                            header: "Show ID",
                                            accessorKey: "show_id",
                                            size: 100
                                        },
                                        {
                                            header: "External ID",
                                            accessorKey: "external_id",
                                            size: 100
                                        },
                                        {
                                            header: "Title ID",
                                            accessorKey: "title_id",
                                            size: 100
                                        },
                                        {
                                            header: "PropEp",
                                            accessorKey: "prop_ep",
                                            size: 100
                                        },
                                        {
                                            header: "Show Timestamp",
                                            accessorKey: "show_timestamp",
                                            Cell: ({cell}) => new Date(cell.getValue() * 1000).toLocaleString("en-US", DATE_DISPLAY_OPTIONS),
                                            enableColumnFilter: false,
                                            size: 200
                                        },
                                        {
                                            header: "Last Updated",
                                            accessorKey: "last_update",
                                            Cell: ({cell}) => new Date(cell.getValue()).toLocaleString("en-US", DATE_DISPLAY_OPTIONS),
                                            enableColumnFilter: false,
                                            size: 200
                                        }
                                    ]}
                                    data={shows}
                                    initialState={
                                        {
                                            density: "compact",
                                            pagination: {
                                                pageSize: 100
                                            },
                                            showColumnFilters: true,
                                            sorting: [
                                                {id: "show_timestamp", asc: true}
                                            ]
                                        }
                                    }
                                    state={{expanded, isLoading:loading}}
                                    enableStickyHeader
                                    enableTopToolbar={false}
                                    enableColumnFilters
                                    enableSortingRemoval={false}
                                    enableExpandAll={false}
                                    onExpandedChange={setExpanded}
                                    autoResetExpanded
                                    muiExpandButtonProps={
                                        ({ row }) => ({
                                            sx: {
                                                transform: row.getIsExpanded() ? "rotate(180deg)" : "rotate(-90deg)",
                                                transition: "transform 0.2s",
                                            },
                                        })
                                    }
                                    muiTableHeadCellFilterTextFieldProps={{
                                        sx: { m: "0.5rem 0", width: "100%" },
                                        variant: "outlined",
                                    }}
                                    renderDetailPanel={
                                        ({row, table}) => {
                                            console.log(row.original);
                                            return (
                                                <TimeMachineShowDetailPanel show={row.original.show} extended_show={row.original.extended_show} toast={props.toast} />
                                            );
                                        }
                                    }
                                    muiTableBodyRowProps={
                                        ({row, isDetailPanel, staticRowIndex, table}) => {
                                            return {
                                                sx: {
                                                    backgroundColor: !isDetailPanel && staticRowIndex % 2 === 0 ? "#f5f5f5" : ""
                                                }
                                            }
                                        }
                                    }
                                />
                            </LocalizationProvider>
                    }
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
}
