import React, {useState, useEffect} from "react";
import {Button, Grid, Icon, Message, Segment, Tab} from "semantic-ui-react";
import TimeMachineDataProvider from "../../../Services/TimeMachineDataProvider";
import {MaterialReactTable} from "material-react-table";
import { LocalizationProvider } from '@mui/x-date-pickers';
import {enUS} from "date-fns/locale";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFnsV3";

const DATE_DISPLAY_OPTIONS = {year: "numeric", month: "numeric", day: "numeric", hour: "numeric", minute: "numeric", second: "numeric"};

export default function TimeMachineTaxonomyViewer(props) {
    const [loading, setLoading] = useState(false);
    const [taxonomies, setTaxonomies] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");
    const [expanded, setExpanded] = useState({});
    const [instanceOptions, setInstanceOptions] = useState([]);
    const [kindOptions, setKindOptions] = useState([]);

    useEffect(() => {
        TimeMachineDataProvider.init(process.env.TIME_MACHINE_ADMIN_URL);

        refreshTaxonomies();
    }, []);

    useEffect(function onTaxonomiesUpdate() {
        const instances = new Set();
        const kinds = new Set();

        for (const taxonomy of taxonomies) {
            for (const instance of Object.keys(taxonomy.taxonomies)) {
                instances.add(instance);
            }

            kinds.add(taxonomy.taxonomy_kind);
        }

        const updatedInstanceOptions = [];
        const updatedKindOptions = [];

        for (const instance of Array.from(instances).toSorted()) {
            updatedInstanceOptions.push({key: instance, text: instance, value: instance});
        }

        for (const kind of Array.from(kinds).toSorted()) {
            updatedKindOptions.push({key: kind, text: kind, value: kind});
        }

        setInstanceOptions(updatedInstanceOptions);
        setKindOptions(updatedKindOptions);
    }, [JSON.stringify(taxonomies)]);

    const refreshTaxonomies = () => {
        setLoading(true);
        TimeMachineDataProvider.getTaxonomies().then(response => {
            if (response.hasOwnProperty("error")) {
                console.log(response.error);
                setErrorMessage("There has been an error retrieving taxonomies from Time Machine.");
            } else {
                console.log("received taxonomies: ", response.taxonomies)
                const updatedTaxonomies = [];
                for (const taxonomy of response.taxonomies) {
                    taxonomy.instances = Object.keys(taxonomy.taxonomies);
                    taxonomy.lastUpdate = new Date(taxonomy.lastUpdate * 1000);
                    taxonomy.lastPublished = new Date(taxonomy.lastPublished * 1000);
                    updatedTaxonomies.push(taxonomy);
                }
                setTaxonomies(updatedTaxonomies);
            }
        }).finally(() => {
            setLoading(false);
        });
    };

    const copyToClipboard = (instance, payload) => {
        navigator.clipboard.writeText(payload).then(() => {
            props.toast("Copied", `You have copied the taxonomy for the instance ${instance} to your clipboard.`, "success");
        });
    }


    return (
        <Grid className="masterContainer">
            <Grid.Row>
                <Button icon onClick={refreshTaxonomies}><Icon name="refresh"/> Refresh</Button>
                {
                    errorMessage.length > 0 ?
                        <Message severity="warning">{errorMessage}</Message> : ""
                }
            </Grid.Row>
            <Grid.Row>
                <Grid.Column width={16}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enUS}>
                        <MaterialReactTable
                            columns={[
                                {
                                    header: "Kind",
                                    accessorKey: "taxonomy_kind",
                                    filterSelectOptions: kindOptions,
                                    filterVariant: "select"
                                },
                                { header: "Display Name", accessorKey: "name" },
                                {
                                    header: "Instances",
                                    accessorKey: "instances",
                                    Cell: ({cell}) => cell.getValue().join(", "),
                                    filterSelectOptions: instanceOptions,
                                    filterVariant: "multi-select"
                                },
                                {
                                    header: "Canonical Instance",
                                    accessorKey: "canonical_instance",
                                    filterSelectOptions: instanceOptions,
                                    filterVariant: "select"
                                },
                                {
                                    header: "Last Update",
                                    accessorKey: "lastUpdate",
                                    Cell: ({cell}) => new Date(cell.getValue()).toLocaleString("en-US", DATE_DISPLAY_OPTIONS),
                                    filterVariant: "datetime-range"
                                },
                                {
                                    header: "Last Published",
                                    accessorKey: "lastPublished",
                                    Cell: ({cell}) => new Date(cell.getValue()).toLocaleString("en-US", DATE_DISPLAY_OPTIONS),
                                    filterVariant: "datetime-range"
                                }
                            ]}
                            data={taxonomies}
                            initialState={
                                {
                                    density: "compact",
                                    pagination: {
                                        pageSize: 100
                                    },
                                    showColumnFilters: true,
                                    sorting: [
                                        {id: "lastUpdate", desc: true}
                                    ]
                                }
                            }
                            state={{expanded, isLoading:loading}}
                            enableStickyHeader
                            enableTopToolbar={false}
                            enableColumnFilters
                            enableSortingRemoval={false}
                            enableExpandAll={false}
                            onExpandedChange={setExpanded}
                            autoResetExpanded
                            muiExpandButtonProps={
                                ({ row }) => ({
                                    sx: {
                                        transform: row.getIsExpanded() ? "rotate(180deg)" : "rotate(-90deg)",
                                        transition: "transform 0.2s",
                                    },
                                })
                            }
                            muiTableHeadCellFilterTextFieldProps={{
                                sx: { m: "0.5rem 0", width: "100%" },
                                variant: "outlined",
                            }}
                            renderDetailPanel={
                                ({row, table}) => {
                                    return (
                                        <Tab
                                            panes={Object.keys(row.original.taxonomies).map(key => {
                                                const taxonomy = row.original;
                                                return {
                                                    menuItem: {
                                                        key: `taxonomy-${taxonomy.name}-${key}`,
                                                        content: <label>{key === row.original.canonical_taxonomy ? <b>key</b> : key}</label>
                                                    },
                                                    render: () =>
                                                        <Segment>
                                                            <Button floated="right" onClick={() => copyToClipboard(key, JSON.stringify(taxonomy.taxonomies[key], null, 4))}>Copy Taxonomy</Button>
                                                            <br />
                                                            <pre>{JSON.stringify(taxonomy.taxonomies[key], null, 4)}</pre>
                                                        </Segment>
                                                }
                                            })}
                                            defaultActiveIndex={0}
                                        />
                                    );
                                }
                            }
                            muiTableBodyRowProps={
                                ({row, isDetailPanel, staticRowIndex, table}) => {
                                    return {
                                        sx: {
                                            backgroundColor: !isDetailPanel && staticRowIndex % 2 === 0 ? "#f5f5f5" : ""
                                        }
                                    }
                                }
                            }
                        />
                    </LocalizationProvider>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
};
