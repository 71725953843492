import React from 'react';
import ReactTable from "react-table-v6";
import {Header, Grid, Button} from 'semantic-ui-react'
import TimeMachineMetaDataProvider from '../../../Services/TimeMachineMetaDataProvider';
import { Prompt } from 'react-router'
import TimeMachineDetailPage from "./TimeMachineDetailPage";
import BasicModal from "../../BasicModal/BasicModal";


class TimeMachineMetadataDetailView extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            instances: [],
            selectedKey: "",
            selectedInstance: {},
            previouslyKey: "",
            previouslyInstance: {},
            currentKey: "",
            currentInstance: {},
            showModal: false,
            saved: false,
            changeEditor:false,
        };

        TimeMachineMetaDataProvider.init({baseURL: process.env.TIME_MACHINE_ADMIN_URL})

        this.getAllMetadata();

    }

    setSaved = (saved) => {
        this.props.handleSetSave(saved)
        this.setState({
            saved
        });
    }

    setChangeEditor = (changeEditor) => {
        this.props.handleSetChangeEditor(changeEditor)
        this.setState({
            changeEditor,
        });
    }

    handleLeave = () => {
        this.props.handleSetChangeEditor(false)
        this.setState({
            changeEditor: false, 
            previouslyInstance: this.state.currentInstance,
            previouslyKey: this.state.currentKey,
            showModal: false,
            currentKey: this.state.selectedKey,
            currentInstance: this.state.selectedInstance,
            selectedInstance: {},
            selectedKey: ""
        })
    }

    handleStay = () => {
        this.setState({
            selectedInstance: {},
            selectedKey: "",
            showModal: false, 
        });
    }

    getAllMetadata = () => {
        TimeMachineMetaDataProvider.getAllMetadata()
            .then(payload => {
                payload.json()
                    .then(instances => {
                        if (Array.isArray(instances)) {
                            this.setState({
                                instances: instances
                            });
                        } else {
                            this.props.toast("Error", "Error getting instances from Time Machine", "error");
                            this.setState({instances: []});
                        }
                    });
            }).catch(error => {
                console.error("TimeMachineMetadataDetailView.getAllMetadata error: ", error);
                this.props.toast("Error", "Error getting instances from Time Machine", "error");
                this.setState({instances: []});
            }).finally(() => {
                this.setState({loading: false});
        });
    };

    handleChange = id => event => {
        let text = event.target.value;
        this.setState({
            [id]: text
        })
    }

    newMetadataClicked = (e, { value }) => {
        this.setState({
            selectedInstance: {},
            newClicked:false
        }, () => {
        });
    }

    handleWindowBeforeUnload = (event) => {
        if (this.state.changeEditor && !this.state.saved ){
            event.preventDefault();
        }
    }

    componentDidMount() {
        window.addEventListener("beforeunload", this.handleWindowBeforeUnload);
    }

    componentWillUnmount() {
        window.removeEventListener("beforeunload", this.handleWindowBeforeUnload);
    }

    render() {
        let that = this;
        return(
            <Grid style={{height: '100vh'}} >
                <BasicModal
                    onClose={this.handleStay}
                    onOpen={this.handleLeave}
                    open={this.state.showModal}
                    headerText={""}
                    modalContent={"Are you sure you want to leave without saving?"}
                />
                <Prompt
                    when={!that.state.saved && that.state.changeEditor}
                    message='Are you sure you want to leave without saving?'
                /> 
                <Grid.Column width={4} className='masterContainer'>
                    <ReactTable
                        columns={[
                            { Header: 'Instance ID', accessor: '_id' }
                        ]}
                        data={this.state.instances}
                        loading={this.state.loading}
                        filterable
                        defaultSorted={[
                            {
                                id: "_id",
                                desc: false
                            }
                        ]}
                        defaultFilterMethod={(filter, rows) => rows[filter.id].toString().toLowerCase().includes(filter.value.toString().toLowerCase())}
                        className="-striped -highlight"
                        getTrProps={(state, rowInfo, column, instance) => {
                            return {
                                onClick() {   
                                    that.setState({
                                        selectedKey: rowInfo.row._id,
                                        selectedInstance: rowInfo.original,
                                    }, () => {
                                        if (that.state.currentKey === ""){
                                            that.setState({
                                                currentKey: that.state.selectedKey,
                                                currentInstance: that.state.selectedInstance,
                                            })
                                        }
    
                                        if (that.state.selectedKey  !==  that.state.currentKey && !that.state.saved && that.state.changeEditor){
                                            that.setState({showModal: true})
                                         
                                        } else {
                                            that.setState({
                                                previouslyInstance: that.state.currentInstance,
                                                previouslyKey: that.state.currentKey,
                                                currentKey: that.state.selectedKey,
                                                currentInstance: that.state.selectedInstance,
                                                selectedInstance: {},
                                                selectedKey: ""
                                            })
                                        }
                                    });
                                },
                                style: {
                                    background: rowInfo && rowInfo.row && that.state.currentKey === rowInfo.row._id ? 'rgba(65, 83, 175, .5)' : '',
                                }
                            }
                        }}
                        style={{height: '75vh'}}
                    />
                    <Button onClick={this.newMetadataClicked}> Add New Metadata </Button>
                </Grid.Column>
                <Grid.Column width={12} className='detailsContainer'>
                    <TimeMachineDetailPage
                        toast={this.props.toast}
                        onUpdateMetadata={this.getAllMetadata}
                        instance={this.state.currentInstance}
                        setPreviouslySelectedKey={this.setPreviouslySelectedKey}
                        previouslySelectedInstance={this.state.previouslySelectedInstance}
                        setSaved={this.setSaved}
                        setChangeEditor={this.setChangeEditor}
                    />
                </Grid.Column>
            </Grid>
        );
    }
}

export default TimeMachineMetadataDetailView;
