import React, {Component} from "react";
import {Button, Form} from "semantic-ui-react";
import TimeMachineMetaDataProvider from "../../../Services/TimeMachineMetaDataProvider";
import JSONEditor from 'jsoneditor'
import 'jsoneditor/dist/jsoneditor.css'


export default class TimeMachineDetailPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            instance: this.props.instance,
            defaultMD: this.props.defaultMD,
            currentEditorText: {},
            changedEditorText: {},
        };
    }

    componentWillUnmount() {
        console.log(`(componentWillUnmount) Started.`)
        if (this.jsoneditor) {
            this.jsoneditor.destroy()
        }
    }

    componentDidMount() {
        const options = {
            mode: 'code',
            onChange: () => {
                const text = this.jsoneditor.get();
                this.props.setChangeEditor(true),
                this.setState({
                    changedEditorText: text
                });
            }
        };
        this.jsoneditor = new JSONEditor(this.container, options);
        this.jsoneditor.set(this.state.instance);

        if(this.props.initialLoad) {
            this.jsoneditor.set(this.state.instance);
            this.props.disableInitialLoad();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        console.log(`(S2STokenJSONEditor componentDidUpdate) Started.`, prevProps, prevState, this.props, this.state);
        if (this.props.instance  !==  prevProps.instance) {
            this.jsoneditor.set(this.props.instance);
        }
    }

    deleteMetadata = () => {

        const instanceJson = this.jsoneditor.get();
        const instanceId = instanceJson._id;

        if (!instanceId) {
            this.props.toast('Message', "You must provide an instance id", 'error');
            return;
        }

        if(instanceId === "default"){
            this.props.toast('Message', "You cannot delete the default Metadata, you're only allowed to modify it.", 'error');
            return;
        }

        var r = confirm("You're about to delete the metadata for "+instanceId+", are you sure you want to proceed? This could have adverse effects on a tenant in Time Machine");
        if (r === false) {
            return;
        }


        TimeMachineMetaDataProvider.deleteMetadata(instanceId)
            .then(resp => {
                resp.json()
                    .then(payload => {
                        if (payload.message) {
                            this.props.toast('Message', payload.message, 'success');

                        } else if (payload.error) {
                            this.props.toast('Message', payload.error, 'error');
                        }

                        this.props.onUpdateMetadata()
                    }).catch(error => {
                    this.props.toast("Error", "We could not delete the metadata", "error");
                });
            });

    };

    updateMetadata = () => {

        const instanceJson = this.jsoneditor.get();
        const instanceId = instanceJson._id;
        if (!instanceId) {
            this.props.toast('Message', "You must provide an instance id", 'error');
            return;
        }

        if(instanceId === "default"){
            var r = confirm("You're about to modify the default metadata, changes here will propagate to every other instance! This can be very dangerous, so please be sure of your changes.");
            if (r === false) {
                return;
            }
        }


        TimeMachineMetaDataProvider.setMetadata(instanceId,instanceJson)
            .then(resp => {
                resp.json()
                    .then(payload => {
                        if (payload.message) {
                            this.props.toast('Message', payload.message, 'success');
                            this.props.setSaved(true)
                        } else if (payload.error) {
                            this.props.toast('Message', payload.error, 'error');
                        }

                        this.props.onUpdateMetadata()
                    }).catch(error => {
                    this.props.toast("Error", "We could not update the metadata", "error");
                });
            });
        
    };

    render() {
        return (
            <Form>
                <Form.Field>
                    <Button.Group>
                        <Button onClick={this.deleteMetadata} size='big'>Delete</Button>
                        <Button.Or />
                        <Button onClick={this.updateMetadata} size='big'  positive>Save</Button>
                    </Button.Group> 
                    <br />
                    <br /> 
                    <div style={{height: "57vh"}} ref={elem => this.container = elem}/>
                </Form.Field>
                <br/>
                <br/>
                <br/>
            </Form>
        )
    }
}
